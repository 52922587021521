<template>
  <ValidationProvider
    ref="fieldAccount"
    :name="$t(label)"
    :rules="required ? 'required' : ''"
    :vid="label"
    slim
  >
    <template #default="{ errors, valid }">
      <v-select
        v-model="iAccountId"
        :error-messages="errors"
        :items="items"
        :label="$t(label)"
        :loading="loading"
        :menu-props="{ offsetY: true }"
        :success="required ? valid : undefined"
        clearable
        dense
        hide-details="auto"
        item-text="name"
        item-value="id"
        outlined
        v-bind="$attrs"
        @change="onChange"
      >
        <template #selection="{ item }">
          <div v-if="itemSelected" class="d-flex align-center">
            <span class="font-weight-bold" v-text="itemSelected.code" />
            <span
              class="d-inline-block ml-3 font-weight-light text-truncate"
              v-text="itemSelected.name"
            />
          </div>
          <span v-else v-text="item" />
        </template>

        <template #item="{ item }">
          <v-list-item-content>
            <v-list-item-title v-text="item.name" />
            <v-list-item-subtitle v-text="item.code" />
          </v-list-item-content>
        </template>
      </v-select>
    </template>
  </ValidationProvider>
</template>
<script lang="ts">
import {
  Account,
  AccountCollection,
  AccountData,
} from "@planetadeleste/vue-mc-gw";
import { Component, Mixins, Prop, VModel, Watch } from "vue-property-decorator";
import { find } from "lodash";
import CacheMixin from "@/mixins/CacheMixin";
import { AppModule } from "@/store/app";

@Component
export default class AccountSelect extends Mixins(CacheMixin) {
  @VModel({ type: Number }) iAccountId!: number;
  @Prop({ type: String, default: "accounts" }) readonly label!: string;
  @Prop(Boolean) readonly required!: boolean;
  @Prop(Number) readonly currency!: number;

  obCollection: AccountCollection = new AccountCollection();
  loading = false;

  get company() {
    return AppModule.company;
  }

  get cacheKey() {
    return `Account/${this.company.id}`;
  }

  get cacheIdentifier() {
    return "models";
  }

  get items() {
    return this.currency
      ? this.obCollection
          .filter<AccountCollection>({ currency_id: this.currency })
          .getModelList()
      : this.obCollection.getModelList();
  }

  get itemSelected() {
    return this.iAccountId
      ? (find(this.items, { id: this.iAccountId }) as
          | Partial<AccountData>
          | undefined)
      : undefined;
  }

  @Watch("currency", { immediate: true })
  onChangeCurrency(iCurrencyID?: number, iOldCurrencyID?: number) {
    if (iCurrencyID === iOldCurrencyID) {
      return;
    }

    this.load();
  }

  mounted() {
    this.$nextTick(this.load);
  }

  async load() {
    this.obCollection.clear();
    this.obCollection.set("filters", {});

    const arItems = this.cache<Account[]>();

    if (arItems && arItems.length) {
      this.obCollection.add(arItems);
      return;
    }

    this.loading = true;
    await this.obCollection.list();
    this.cache(this.obCollection.models);
    this.loading = false;
  }

  onChange(sValue: number) {
    const obModel = this.obCollection.find({ id: sValue });
    this.$emit("change", obModel);
  }
}
</script>
